@import './inc/style.scss';
@import './inc/tooltipster-customize.scss';
@import './inc/sweetalert-customize.scss';
@import './inc/pager-area.scss';

.profile-page-inner {
    width:calc(100% - 350px);
    max-width:740px;
}

.profile-page-menu {
    width:300px;

    .icon-outer {
        width:28px;
        height:28px;

        .icon {
            width:100%;
            height:100%;
            color:$dark-gray;
        }
    }

    .menu-header {
        margin-bottom:40px;

        .title {
            font-weight:$normal-fweight;
            margin-bottom:4px;
        }

        .text-content {
            width:calc(100% - 28px);
            padding-left:12px;
        }
    }

    .menu-items {
        .row {
            &:not(.logout-row) {
                border-bottom:2px solid $beige;
            }
        }

        a {
            font-size:16px;
            line-height:20px;
            text-decoration:none;
            color:$primary-color;
            display:block;
            padding:20px;

            &.active {
                color:$blue;
                background:$light-blue;
            }
        }

        .logout-row {
            a {
                color:$dark-gray;
                font-weight:$normal-fweight;
                display:inline-block;
            }
        }
    }
}

.profile-title-content {
    margin-bottom:40px;
    
    .address-types {
        margin-top:10px;
    }

    .type-selector {
        margin-right:15px;
        font-weight:$medium-fweight;
        transition:$color-transition, $border-color-transition, $bg-color-transition;
        background:$white;
        border:1px solid $dark-gray;
        color:$primary-color;
        text-decoration:none;
        height:36px;
        font-size:14px;
        line-height:14px;
        padding:11px 10px 9px 10px;
        font-weight:$bold-fweight;
        
        &:last-of-type {
            margin-right:0px;
        }

        &.active {
            border-color:$light-blue;
            background-color:$light-blue;
            color:$blue;
        }
    }
}

.orders-table {
    .title-row {
        @include secondaryText();
        font-weight:$bold-fweight;
        letter-spacing:$secondary-lspacing;
    }

    .order-row {
        display:grid;
        gap:0px 30px;
        border-bottom:2px solid $beige;
        transition:$border-color-transition;
        grid-template-columns: 70px 133px 70px calc(100% - 526px) 80px 23px;
        grid-template-areas: 
        "identity recipient package status price button"
        "details details details details details details";

        &.opened {
            border-color:$light-gray;
        }

        .col {
            padding-top:20px;
            padding-bottom:20px;
        }

        .identity-col {
            grid-area:identity;
        }

        .recipient-col {
            grid-area:recipient;
        }

        .package-col {
            grid-area:package;
        }

        .status-col {
            grid-area:status;
        }

        .price-col {
            grid-area:price;
        }

        .button-col {
            grid-area:button;
            align-self:center;
        }

        .details-outer {
            grid-area: details;
            display:none;
        }

        &.title-row {
            .col {
                padding-top:0px;
                padding-bottom:5px;
            }
        }

        .arrow-link {
            padding:5px;
            display:block;
            width:18px;
            height:15px;
            transition: $transform-transition;

            &.opened {
                transform:rotate(180deg);
            }
        }
    }

    .details-table {
        background:$light-gray;
        
        .details-row {
            display:grid;
            gap: 0px 30px;
            grid-template-columns: calc(100% - 330px) 130px 140px;
            padding:15px;
            border-bottom:2px solid $beige;

            .col {
                padding:0px;
            }

            &:last-of-type {
                border-bottom:none;
            }

            &.title-row {
                padding-bottom:5px;
            }
        }
    }
}

.bill-link {
    text-decoration:none!important;

    .icon {
        min-width:16px;
        margin-right:8px;
    }

    .text {
        @include secondaryText();
        font-weight:$bold-fweight;
        text-decoration:underline;
    }
}

.addresses-page-inner {
    .trash-icon {
        width:17px;
        height:20px;
    }

    .addresses {
        > h3 {
            font-weight:$normal-fweight;
            margin-bottom:25px;
        }
    }

    .address {
        .address-content {
            width:calc(100% - 120px);
        }

        .features {
            width:100px;
            font-size:14px;
            line-height:22px;
    
            > * {
                margin-right:9px;
    
                &:last-child {
                    margin-right:0px;
                }
            }
        }
    }

    .address-block {
        margin-bottom:25px;

        .form-outer {
            padding:25px 0px 25px 0px;
            border-bottom:2px solid $beige;
            display:none;
        }

        .subtitle {
            font-size:18px;
            line-height:22px;
            margin-bottom:20px;
        }
    }

    .info-icon {
        width:16px;
        height:16px;
        min-height:16px;
        min-width:16px;
        color:$blue;
        margin-left:2px;
        font-size:0px;
        line-height:0px;
    }

    .tooltip-row {
        display:flex;
        
        .info-item {
            font-size:0px;
            line-height:0px;
            width:16px;
            min-width:16px;
            height:16px;
            display:block;
            transform:translateY(-2px);
        }
    }
    
    .contact-block {
        margin-top:40px;
    }

    .pager-section {
        .inner-content {
            justify-content: center;
        }
    }
}

.group-send-form {
    max-width:400px;
}

.custom-file-row {
    .custom-file-input {
        display:none;
    }

    .custom-file-input-label {
        margin:0px;
        display:flex;
        align-items:center;
        justify-content:flex-start;

        .label {
            font-size:14px;
            line-height:16px;
            margin-left:10px;
        }
    }
}

.order-details-page-inner {
    .highlighted-informations {
        background:$light-gray;
        padding:15px;

        .box {
            margin-right:30px;

            &:last-of-type {
                margin-right:0px;
            }
        }

        .label {
            font-size:14px;
            line-height:14px;
            margin-bottom:3px;
        }
    }

    .summary-boxes {
        display:grid;
        grid-gap:30px 50px;
        grid-template-columns: repeat(2, calc((100% - 50px) / 2));

        .full-width-box {
            grid-column: 1/3;
        }

        h3 {
            margin-bottom:8px;
        }
        
        .subtitle {
            font-weight:$bold-fweight;
            margin:12px 0px 5px 0px;
        }

        &.with-border {
            border-top: 1px solid $beige;
            margin-top:25px;
            padding-top:25px;
        }

        ul {
            margin-left:10px;
            li {
                font-size:16px;
                line-height:22px;
                padding-left:18px;
                position:relative;
                margin-bottom:8px;

                &:last-of-type {
                    margin-bottom:0px;
                }

                &:before {
                    content:'';
                    width:8px;
                    height:8px;
                    border-radius:50%;
                    background:$blue;
                    display:block;
                    left:0px;
                    position:absolute;
                    top:5px;
                }
            }
        }
    }
}

@include hoverStatements {
    .profile-page-menu {
        .menu-items {
            a:not(.active) {
                &:hover {
                    text-decoration:underline;
                }
            }
        }
    }

    .bill-link {
        &:hover {
            .text {
                text-decoration:none;
            }
        }
    }

    .orders-table {
        .arrow-link {
            &:hover {
                color:$primary-color;
            }
        }
    }

    .profile-title-content {
        .type-selector {
            &:hover {
                &:not(.active) {
                    color:$blue;
                    border-color:$blue;
                }
            }
        }
    }
}

@include breakpoint($mobile-menu-breakpoint) {
    .profile-page-inner {
        width:calc(100% - 310px);
    }

    .profile-page-menu {
        width:270px;
    }
}

@include betweenBreakpoint($lying-tablet-width, $mobile-menu-breakpoint) {
    .addresses-page-inner {
        .address-block {
            .street-col, .number-col {
                &:not(.full-width) {
                    width:calc(50% - 10px);
                }
            }
        }
    }
}

@include below-lying-tablet {
    .profile-page-inner {
        width:100%;
        margin:auto;
    }

    .profile-page-menu {
        display:none;
    }
}

@include below-standing-tablet {
    .orders-table {
        .order-row.title-row, .details-row.title-row {
            display:none!important;
        }

        .order-row {
            margin-bottom:15px;
            position:relative;
            gap:0px 20px;
            grid-template-columns: 70px calc(100% - 240px) 130px;
            grid-template-areas: 
            "identity recipient package"
            "status status price"
            "details details details";

            &:last-of-type {
                margin-bottom:0px;
            }

            .col {
                padding-top:0px;
                padding-bottom:15px;

                &:before {
                    content:attr(data-label);
                    display:block;
                    font-size:14px;
                    line-height:14px;
                    margin-bottom:5px;
                }
            }

            .button-col {
                position:absolute;
                right:10px;
                top:15px;
            }
        }

        .details-row {
            .col:before {
                font-weight:$bold-fweight;
            }
        }
    }
}

@include mobile {
    .profile-page-inner {
        .button {
            width:100%;
        }
    }

    .profile-title-content {
        margin-bottom:30px;

        .address-types {
            display:grid;
            gap:15px 15px;
            grid-template-columns: repeat(2, calc(50% - 5px));
            grid-template-columns: 100%;
        }

        .type-selector {
            margin-right:0px;
            text-align:center;
            height:40px;
            display:flex;
            align-items:center;
            justify-content:center;
        }
    }

    .group-send-form {
        max-width:none;
    }

    .custom-file-row {
        .custom-file-input-label {
            .button {
                width:auto;
            }
        }
    }

    .orders-table {
        .order-row {
            grid-template-columns: 115px calc(100% - 135px);
            grid-template-areas: 
            "identity recipient"
            "package price"
            "status status"
            "details details";
            .col {
                &:before {
                    font-size:12px;
                    line-height:12px;
                    margin-bottom:3px;
                }
            }

            .identity-col, .package-col, .status-col {
                padding-left:#{map-get($sidePaddings, "mobile")};
            }

            .recipient-col {
                padding-right:30px;
            }
        }

        .details-table {
            .details-row {
                gap:15px 20px;
                grid-template-columns:repeat(2, calc(50% - 10px));
                grid-template-areas: 
                "address address"
                "lading bill";

                .address-col {
                    grid-area:address;
                }

                .lading-col {
                    grid-area: lading;
                }

                .bill-col {
                    grid-area: bill;
                }
            }
        }
    }

    .profile-page-body {
        &.group-orders-page, &.orders-page-body, &.order-details-page-body {
            .profile-page-content {
                > .content-width {
                    padding:0px;
                }

                .profile-title-content {
                    padding-right:#{map-get($sidePaddings, "mobile")};
                    padding-left:#{map-get($sidePaddings, "mobile")};
                }
            }
        }
    }

    .order-details-page-inner {
        .summary-boxes {
            display:block;
            margin-bottom:25px;
            padding-right:#{map-get($sidePaddings, "mobile")};
            padding-left:#{map-get($sidePaddings, "mobile")};

            &.with-border {
                border-top:none;
                position:relative;

                &:before {
                    content:'';
                    position:absolute;
                    top:0px;
                    left:0px;
                    right:0px;
                    margin:auto;
                    background:$beige;
                    height:1px;
                    width:calc(100% - (#{map-get($sidePaddings, "mobile")} * 2));
                }
            }

            .box {
                margin-bottom:20px;

                &:last-of-type {
                    margin-bottom:0px;
                }
            }

            ul {
                li {
                    padding-left:14px;
                    font-size:14px;
                    line-height:20px;

                    &:before {
                        width:6px;
                        height:6px;
                        top:6px;
                    }
                }
            }
        }

        .highlighted-informations {
            display:grid;
            gap:15px 25px;
            grid-template-columns: 100px calc(100% - 125px);
            grid-template-areas: 
            "package status"
            "price bill";

            .package-box {
                grid-area: package;
            }

            .price-box {
                grid-area: price;
            }
            
            .status-box {
                grid-area: status;
            }
            
            .bill-box {
                grid-area: bill;
            }
        }
    }
}