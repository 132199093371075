@import './core/tooltipster.scss';

.tooltipster-sidetip {
    .tooltipster-content {
        font-size: 12px;
        line-height: 14px;
        padding: 8px 7px 8px 7px;
        padding: 15px;
        border-radius:2px;
        border: 1px solid $dark-gray;
        text-align:left;
    }

    .tooltipster-box {
        box-shadow: none;
        width:auto;
        max-width: 230px;
    }

    .tooltipster-arrow {
        width: 20px !important;
        height: 20px !important;

        .tooltipster-arrow-border {
            width: 100%;
            height: 100%;
            border: 1px solid $dark-gray;
            background: $white;
            transform: rotate(45deg);
        }

        .tooltipster-arrow-uncropped {
            width: 14px;
            height: 14px;
        }
    }

    &.tooltipster-top,
    &.tooltipster-bottom {
        .tooltipster-arrow-background {
            width: 30px;
            height: 12px;
            left: -6px;
            border: none;
            background: $white;
        }

        .tooltipster-arrow {
            .tooltipster-arrow-uncropped {
                margin: auto;
            }
        }
    }

    &.tooltipster-left {
        .tooltipster-arrow-background {
            width:12px;
            height:30px;
            border: none;
            background:$white;
            left:-1px;
        }

        .tooltipster-arrow-border {
            left:2px;
        }
    }

    &.tooltipster-top {
        .tooltipster-arrow-border {
            left: 2px;
        }

        .tooltipster-arrow {
            bottom: -2px;
        }
    }

    &.tooltipster-bottom {
        .tooltipster-arrow-uncropped {
            top: 3px;
        }

        .tooltipster-arrow-border {
            left: 2px;
        }

        .tooltipster-arrow-background {
            top: 6px;
        }
    }
}