.swal2-container {
    font-family:$primary-font-family;
    color:$primary-color;
    
    &.swal2-shown {
        background:rgba(57, 57, 57, 0.34);
    }

    .swal2-title, .swal2-content {
        color:$primary-color;
        text-align:left;
    }

    .swal2-title {
        @include headerStyle("desktop", "h2");
        margin-bottom:15px;
    }

    .swal2-content {
        @include secondaryText("desktop");
        text-align:center;
    }

    .swal2-popup {
        border-radius:2px;
        padding:40px 50px 55px;
        box-shadow: 0 0 15px 0 rgba(0,0,0,0.08);
    }

    .swal2-header {
        justify-content:center;
        align-items:center;

        .swal2-icon {
            display:none!important;
        }
    }

    .swal2-actions {
        display:flex;
        align-items:center;
        justify-content:center;
        margin-top:40px;

        button {
            margin-right:20px;

            &:last-of-type {
                margin-right:0px;
            }
        }

        .swal2-cancel {
            background:$white;
            color:$blue;
        }
    }
}

@include hoverStatements {
    .swal2-container {
        .swal2-actions {
            .swal2-cancel {
                &:hover {
                    color:$white;
                    background:$blue;
                }
            }
        }
    }
}

@include mobile {
    .swal2-container {
        .swal2-popup {
            padding:30px 15px 25px 15px;
        }
    }
}